import { render, staticRenderFns } from "./WhiteLabelDomain.vue?vue&type=template&id=cdba473a&scoped=true&"
import script from "./WhiteLabelDomain.vue?vue&type=script&lang=js&"
export * from "./WhiteLabelDomain.vue?vue&type=script&lang=js&"
import style0 from "./WhiteLabelDomain.vue?vue&type=style&index=0&id=cdba473a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdba473a",
  null
  
)

export default component.exports